<template>
  <KCourse loader-class="MBcont" course-id="5" title="Шарнирная кукла" :items="items">
    <div class="MBtextcont" :style="getHiddenStyle">
      <!-- <p v-if="isFirstPage" class="nomargin"><a class="a-download" href="/img/files/DrawingBJD1.jpg">Скачать чертеж.
        Часть 1</a></p>
      <p v-if="isFirstPage" class="nomargin"><a class="a-download" href="/img/files/DrawingBJD2.jpg">Скачать чертеж.
        Часть 2</a></p> -->

      <p>Приветствую вас. Мы начинаем! Для меня это всегда радостно и волнительно, потому что это начало нового этапа, новых знаний и создание новой куклы… Какой она будет, зависит только от вас 😍 а мы постараемся быть вам максимально полезными.</p>
      <p>1. Вступайте в <a href="https://vk.com/invite/GM8PSfc">закрытую группу</a></p>
      <p>2. Подпишитесь на <a href="https://vk.com/app5898182_-204845739#s=1330664">рассылку для получения уроков </a></p>
      <p>3. Подготовьте все недостающие материалы, <a href="https://kibanovadolls.ru/mycourses/bjd-materials">весь список можно посмотреть тут </a></p>
      <p>4. Прочитайте <a href="/mycourses/homework">Как сдавать ДЗ куратору / в чат?</a></p>
    </div>
    <div id="script"></div>
    <KPdf :src="getPdfSrc"/>
  </KCourse>
</template>

<script>
import KCourse from "../../Widgets/KCourse";
import KPdf from "../../Widgets/KPdf";
import {ref, watchEffect, onMounted} from "vue";
import {useRouter} from "vue-router";
import {loadVideo} from "../../../service/api";
import md5 from "md5";

export default {
  name: "Bjd",
  components: {
    KPdf,
    KCourse,
  },
  setup() {
    const router = useRouter();
    const otp = ref(null)
    const videoId = ref(null)


    const loadOtp = (_videoId) => {

      return loadVideo(_videoId)
        .then(response => {
          otp.value = response.data.data.otp
          videoId.value = _videoId
        })
        .catch(error => {
          //todo add error view
          console.log(error)
          if (error.response.status === 404) {
            router.push("/404")
          }
        })
    }

    onMounted( () => {
      var my_awesome_script = window.document.createElement('script');
      my_awesome_script.setAttribute('src','//dev.vdocipher.com/playerAssets/1.6.10/vdo.js');
      let element = window.document.head
      element.append(my_awesome_script)
    })

    return {otp, loadOtp, videoId};
  },
  computed: {
    getHiddenStyle() {
      let path = this.$route.path
      let lastPathSegment = path.substring(path.lastIndexOf('/') + 1)
      console.log(lastPathSegment)
      if (lastPathSegment !== "1") {
        return 'display:none';
      }
    },
    getPdfSrc() {// eslint-disable-line
      this.setClickHandler()
      let path = this.$route.path
      let lastPathSegment = path.substring(path.lastIndexOf('/') + 1)
      let fileName = "BJD" + lastPathSegment + md5("BJD_" + lastPathSegment)
      let fullPath = "/files/BJD/" + fileName + ".pdf"

      if (this.doesFileExist(fullPath)) {
        return fullPath
      } else {
        this.$router.push("/404")// eslint-disable-line
      }
    },
    isFirstPage() {
      return this.getLastPathSegment() == '1'
    },
  },
  created() {


    watchEffect(() => {
      if (this.otp === null || this.otp === undefined) {
        return
      }
      let videoWidth = window.innerWidth < 800 ? window.innerWidth : 800
      let videoId = this.videoId
      this.$swal.fire({
        width: videoWidth + 'px',
        background: '#eae2e0',
        html: '<div id="video' + videoId + '"></div>',
        showCloseButton: true,
        showConfirmButton: false,
        // closeButtonHtml: "",
        didOpen: () => {
          new VdoPlayer({ // eslint-disable-line
            otp: this.otp,
            playbackInfo: btoa(JSON.stringify({
              videoId: videoId
            })),
            theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
            // the container can be any DOM element on website
            container: document.querySelector("#video" + videoId),
          });
        },
        didDestroy: () => {
          this.otp = null
        }
      })
    })
  },
  methods: {

    setClickHandler() {
      if (!window.addEventListener) {
        // IE8 support (could also use an addEventListener shim)
        window.attachEvent('onmessage', this.handleMessage);
      } else {
        window.addEventListener('message', this.handleMessage, false);
      }


    },
    handleMessage(event) {
      if (event.data.videoPopup) {
        let data = event.data.videoPopup.split("=")
        this.loadOtp(data[1])
      }

    },
    doesFileExist(urlToFile) {
      var xhr = new XMLHttpRequest();
      xhr.open('HEAD', urlToFile, false);
      xhr.send();

      if (xhr.status == "404") {
        return false;
      } else {
        return true;
      }
    },
    getLastPathSegment() {
      let path = this.$route.path
      return path.substring(path.lastIndexOf('/') + 1)
    },
  },
  data() {
    return {
      items: [
        {chapter_id: 37, title: "Создаем основу", url: "/mycourses/Bjd/1", state: "", payment_step:3},
        {chapter_id: 38, title: "Работаем с основой", url: "/mycourses/Bjd/2", state: "", payment_step:3},
        {chapter_id: 39, title: "Лепим лицо", url: "/mycourses/Bjd/3", state: "", payment_step:2},
        {chapter_id: 40, title: "Доработка деталей", url: "/mycourses/Bjd/4", state: "", payment_step:2},
        {chapter_id: 41, title: "Делаем шарниры", url: "/mycourses/Bjd/5", state: "", payment_step:1},
        {chapter_id: 42, title: "Шарнироприемники", url: "/mycourses/Bjd/6", state: "", payment_step:1},
        {chapter_id: 43, title: "Доработка и сборка", url: "/mycourses/Bjd/7", state: "", payment_step:1},
        {chapter_id: 44, title: "Доработка. Часть 2", url: "/mycourses/Bjd/8", state: "", payment_step:1},
        {chapter_id: 45, title: "Грунтуем, красим", url: "/mycourses/Bjd/9", state: "", payment_step:0},
        {chapter_id: 46, title: "Расписываем пастелью", url: "/mycourses/Bjd/10", state: "", payment_step:0},
        {chapter_id: 47, title: "Парик и не только...", url: "/mycourses/Bjd/11", state: "", payment_step:0},
        {chapter_id: 48, title: "Сложные шарниры", url: "/mycourses/Bjd/12", state: "", payment_step:0},
        {chapter_id: 49, title: "Построение чертежа", url: "/mycourses/Bjd/13", state: "", payment_step:0},
      ],
    }
  }
}
</script>